import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap';
import interactionPlugin from "@fullcalendar/interaction";
import axios from 'axios';
import Moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useState, useEffect } from 'react';
import { getUrl } from '../planner/planner';

export default function App(props) {
	const [data, setData] = useState([]);
	const [dialog, setDialog] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [type, setType] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if(data.length == 0) {
			axios.get(getUrl() + '/wp-json/planner/v1/bookings?category_id=' + props.employeeCategoryId, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => {
				setData(res.data.bookings);
				setOptions(res.data.booking_options);
				setIsLoaded(true);

			}).catch(err => {
				console.log(err)
			});
		}
	}, []);

	const onSelect = async (data) => {
		let { start, end, allDay } = data;
		if(Moment(end).diff(start, 'days') === 1 && allDay === true) {
			setStartDate(start);
			setEndDate(end);

			setDialog(true);
		}
	}

	function getBackgroundColor(type) {
		let backgroundColor = '#6366F1';
		let textColor = '#fff';

		options.forEach(function(option) {
			if(type === option.option_name) {
				backgroundColor = option.colour_background;
				textColor = option.colour_text;
			}
		});

		return {
			backgroundColor: backgroundColor,
			textColor: textColor
		}
	}

	async function createBooking() {
		let id = await axios.post(getUrl() + '/wp-json/planner/v1/bookings', {
			author_id: 1,
			category_id: props.employeeCategoryId,
			date_from: Moment(startDate).format("YYYY-MM-DD"),
			option_name: type,
		}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then().catch(err => console.log(err));

		setData(
			data.concat({
				backgroundColor: getBackgroundColor(type).backgroundColor,
				borderColor: getBackgroundColor(type).backgroundColor,
				textColor: getBackgroundColor(type).textColor,
				title: type,
				start: Moment(startDate).format("YYYY-MM-DD"),
				end: Moment(endDate).format("YYYY-MM-DD"),
				id: null
			})
		);

		setDialog(false);
	}

	let loadingClass = 'isLoading';

	if(isLoaded) {
		loadingClass = 'notLoading';
	}

	document.title = 'Employee Bookings - Planner';

	return (<>
		<Dialog blockScroll={true} header="Add Booking" visible={dialog} onHide={(e) => setDialog(false)}>
			<Dropdown
				options={options}
				optionLabel="option_name"
				optionValue="option_name"
				value={type}
				placeholder="Booking Type"
				className="w-100"
				onChange={(e) => setType(e.target.value)}
			/>
			<Button type="button" label="Save Booking" icon="pi pi-send" className="mt-4" onClick={createBooking} />
		</Dialog>
		<div className="edit-order-bar position-sticky top-0 py-3 mb-5" >
			<div className="mx-5 px-0 d-flex justify-content-between align-items-center w-100" style={{maxWidth: "100%"}}>
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						<div>
							<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {props.updateSidebarOpened(true);}}>
								<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
							</Button>
						</div>
						<h1 className="mb-0 h3">Employees Calendar</h1>
					</div>
				</div>
			</div>
		</div>
		{
			!isLoaded && (
				<div class="mt-5">
					<div className={"mt-5 mx-5 mb-3"} style={{ position: 'absolute', top: '17.5rem', zIndex: '99999', left: '45%' }}>
						<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem' }}></i>
					</div>
				</div>
			)
		}
		<div className={"mx-5 mb-3 " + loadingClass}>
			<FullCalendar
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrap5Plugin]}
				headerToolbar={{
					center: 'dayGridMonth,timeGridWeek'
				}}
				selectable
				initialView="timeGridWeek"
				slotMinTime={"06:00"}
				slotMaxTime={"19:00"}
				hiddenDays={[0, 6]}
				firstDay={1}
				contentHeight="auto"
				eventDurationEditable={false}
				events={data}
				slotDuration={'00:15:00'}
				allDaySlot={true}
				slotLabelFormat={{
					hour: 'numeric',
					minute: '2-digit',
					omitZeroMinute: false,
					meridiem: false,
					hour12: false
				}}
				views={{
					timeGridWeek: {
						titleFormat: {
							month: 'long',
							day: '2-digit',
							year: 'numeric'
						},
						dayHeaderContent: (e) => {
							return <div>{Moment(e.date).format('dddd Do')}</div>
						}
					}
				}}
				allDayText="All Day"
				select={onSelect}
				nowIndicator={true}
				eventContent={(event) => {
					return(<div>
						{
							<div className="d-flex align-items-center mx-1 time">
								<div>{event.timeText}</div>
							</div>
						}
						<div className="mx-1">
							<strong className="d-block">{event.event.extendedProps.userDisplayName}</strong>
							<div>{event.event.title}</div>
						</div>
					</div>)
				}}
			/>
		</div>
	</>);
}

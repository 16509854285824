import React, { Component } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';

import { getUrl, formatDate } from '../planner/planner';

export class Scraps extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			scraps: [],
			isLoaded: false,
			binDialog: false,
			tableCount: 0,
			filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			globalFilterValue: '',
			columns: [
				{ field: 'date', header: 'Date', sortable: true },
				{ field: 'traveller_number', header: 'Traveller #' },
				{ field: 'material_sku', header: 'Material Code', sortable: true },
				{ field: 'operator_name', header: 'Operator', sortable: true },
				{ field: 'reason', header: 'Issue', sortable: true },
			],
			visibleColumns: [],
			errorToast: React.createRef()
		}

		this.getMaterial = this.getMaterial.bind(this);
		this.getScrapTitle = this.getScrapTitle.bind(this);
		this.getEditButton = this.getEditButton.bind(this);
	}

	/**
	 * Update the global filtering to filter table contents
	 * 
	 * @param {input} e Information related to the current input
	 */
	onGlobalFilterChange(e) {
		const value = e.target.value;

		let _filters = { ...this.state.filters };

		_filters['global'].value = value;

		this.setState({filters: _filters, globalFilterValue: value })
	}

	/**
	 * When the component isLoaded c = this.state;reated get the data from the REST API and set the columns based on the current users preferences
	 */
	componentDidMount() {
		axios.get(getUrl() + '/wp-json/planner/v1/travellers/scraps', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			scraps: res.data,
			tableCount: res.data.length,
			isLoaded: true
		})).catch(err => {
			this.state.errorToast.current.show({ severity: 'error', summary: 'Oops 🤦‍♂️', sticky: true, detail: 'An error has occurred, please contact IT support.' });

			console.log(err)
		});

		document.title = 'Scraps - Planner';

		// If the user has selected custom columns, pre load these or use the default.
		if(localStorage.getItem('visibleColumnsTraveller')) {
			this.setState({
				visibleColumns: JSON.parse(localStorage.getItem('visibleColumnsTraveller'))
			});
		} else {
			this.setState({
				visibleColumns: this.state.columns
			});
		}
	}

	/**
	 * Get the material that has been scrapped with the quantity that's been scrapped
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} Get the material and make it clickable with the quantity in a badge next to each material
	 */
	getMaterial(rowData) {
		if(this.props.capabilities.edit_products === true) {
			return <p className="mb-0"><Link to={"/product/" + rowData.material_id}>{rowData.material_sku}</Link> <span className="badge bg-primary text-white">x {rowData.material_qty}</span></p>
		} else {
			return <p className="mb-0">{rowData.material_sku} <span className="badge bg-primary text-white">x {rowData.material_qty}</span></p>
		}
	}

	/**
	 * Get the traveller id and make it clickable to go to the traveller page
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} The traveller ID with a clickable link to go to that traveller page
	 */
	getTravellerNumber(rowData) {
		return <Link to={"/traveller/" + rowData.traveller_id}>{rowData.traveller_number}</Link>
	}

	/**
	 * Get the edit button for the scrap
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} The edit button for the scrap
	 */
	getEditButton(rowData) {
		const url = "scrap/" + rowData.id;
		let icon = <i className="pi pi-lock me-1" style={{ fontSize: '0.8rem' }}></i>;
		let text = 'View';

		// Check if the current row is locked, change the icon and text based on if it is or not
		if((rowData.locked === false || rowData.locked.ID === this.props.currentLoggedInUser.id) && this.props.capabilities.edit_travellers == true) {
			icon = <i className="pi pi-pencil me-1" style={{ fontSize: '0.8rem' }}></i>;
			text = 'Edit';
		}

		return (<Link to={url} className="btn btn-primary">{icon} {text}</Link>);
	}

	/**
	 * Get the trunctated reason for the scrap
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {string} The truncated reason for the scrap
	 */
	getReason(rowData) {
		let suffix = '';

		if(rowData.issue.length > 50) {
			suffix = ' [...]';
		}

		return rowData.issue.substring(0, 50) + suffix;
	}

	/**
	 * Format the date for the scrap
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {string} The formatted date and time
	 */
	getDate(rowData) {
		return formatDate(rowData.date_reported, true);
	}

	/**
	 * Clear the global search filter
	 */
	clearSearch() {
		let _filters = { ...this.state.filters };

		_filters['global'].value = null;

		this.setState({
			globalFilterValue: '',
			filters: _filters
		})
	}

	/**
	 * Sets the state for the selected columns and saves in local storage so it's remembered for future sessions.
	 * 
	 * @param {Event} event The selected column
	 */
	onColumnToggle(event) {
		let selectedColumns = event.value;
		let orderedSelectedColumns = this.state.columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

		this.setState({visibleColumns: orderedSelectedColumns})
		localStorage.setItem('visibleColumns', JSON.stringify(orderedSelectedColumns));
	}

	/**
	 * Get the HTML to create the filtering options on the table header
	 * 
	 * @returns The HTML for the header for the table
	 */
	renderHeader() {
		return (
			<div className="container">
				<div className="d-flex justify-content-end">
					<div>
						{
							<MultiSelect placeholder="Select Scrap Columns" value={this.state.visibleColumns} options={this.state.columns} onChange={this.onColumnToggle.bind(this)} optionLabel="header" className="me-2 columnToggling" display="chip" />
						}
						<span className="p-input-icon-left p-input-icon-right">
							<i className="pi pi-search" />
							<InputText placeholder="Search..." value={this.state.globalFilterValue} onChange={this.onGlobalFilterChange.bind(this)} />
							{
								this.state.globalFilterValue !== null && this.state.globalFilterValue !== '' && (
									<i className="pi pi-times" onClick={this.clearSearch.bind(this)} />
								)
							}
						</span>
					</div>
				</div>
			</div>
		)
	}

	getScrapTitle() {
		if(this.props.capabilities.edit_travellers === true || this.props.capabilities.view_travellers === true) {
			return this.props.title;
		} else {
			return 'Access Denied'
		}
	}

	getOperator(rowData) {
		return <Link to={'/employee/' + rowData.reported_by_user_id}>{rowData.reported_by_display_name}</Link>
	}

	render() {
		const { errorToast, isLoaded, filters, tableCount, visibleColumns } = this.state;

		let loadingClass = 'isLoading';

		if(isLoaded) {
			loadingClass = 'notLoading';
		}

		return (
			<>
				<Toast ref={errorToast} position="center" />
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5" >
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center w-100" style={{maxWidth: "100%"}}>
						<div className="d-flex">
							<div className="d-flex align-items-center">
								<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.updateSidebarOpened(true);}}>
									<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
								</Button>
							</div>
							<div>
								<h1 className="mb-0 h3">{this.getScrapTitle()}</h1>
								{
									(this.props.capabilities.edit_travellers || this.props.capabilities.view_travellers) && (<><strong>Total Scraps:</strong> {tableCount} of {this.state.scraps.length}</>)
								}
							</div>
						</div>
					</div>
				</div>
				{
					(this.props.capabilities.edit_travellers || this.props.capabilities.view_travellers) && ( // https://stackoverflow.com/questions/70682832/
						<div className="card mx-5 mb-5">
							{
								!isLoaded && (
									<div className={"mx-5 mb-3"} style={{ position: 'absolute', top: '3rem', zIndex: '99999', left: '45%' }}>
										<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem' }}></i>
									</div>
								)
							}
							<DataTable className={loadingClass} paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]} onValueChange={filteredData => this.setState({tableCount: filteredData.length})} dataKey="id" size="small" sortOrder={-1} sortField="id" globalFilterFields={['material_sku', 'traveller_id', 'operator_name']} filters={filters} value={this.state.scraps} tableStyle={{ minWidth: '50rem' }} header={this.renderHeader(this)}>
							<Column key="scrap_number" field="scrap_number" header="Scrap #" sortable={true} />
							{visibleColumns.map((col) => {
									// Absolute shit code but LocalStorage won't store the body functions. See if there's a better way to do this.
									if(col.field === 'date') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getDate} />)
									} else if(col.field === 'traveller_number') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getTravellerNumber} />)
									} else if(col.field === 'material_sku') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getMaterial} />)
									} else if(col.field === 'operator_name') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getOperator} />)
									} else if(col.field === 'reason') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getReason} />)
									} else {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} />)
									}
								})}
								<Column key="edit" field="edit" header="" body={this.getEditButton} style={{ minWidth: '6rem' }} />
							</DataTable>
						</div> 
					)
				}
			</>
		);
	}
}
export default Scraps
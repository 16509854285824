import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getUrl, getJobTimeFormat, formatDate } from '../planner/planner';
import Moment from 'moment';

import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from 'primereact/tooltip';

export function Timer(props) {
	const [availableProcess, setavailableProcess] = useState([]);
	const [process] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [quantity, setQuantity] = useState(1);
	const [showSignOff, setShowSignOff] = useState(false);
	const [showQuantity, setShowQuantity] = useState(false);
	const [expectedIntervalQuantity, setExpectedIntervalQuantity] = useState(0);
	const [dialogRowData, setDialogRowData] = useState(null);
	const [dialogQuantity, setDialogQuantity] = useState(null);
	const [selectedSupervisor, setSelectedSupervisor] = useState(null);
	const [timerProcesses, setTimerProcesses] = useState([]);
	const [confirmRefreshDialog, setConfirmRefreshDialog] = useState(false);
	const [secondsTaken, setSecondsTaken] = useState(0);
	const toast = useRef(null);

	/**
	 * Increment the timer
	 */
	useEffect(() => {
		// If the system hasn't been loaded get available processes, operators and all inforamtion related to the traveller
		if(!loaded) {
			setQuantity(props.traveller.product_qty)

			if(props.traveller.traveller_activities !== false) {
				setTimerProcesses(props.traveller.traveller_activities)
			}

			setLoaded(true);
		}

		setavailableProcess(props.traveller.product_activities)

		let intervalId;

		// Check if each process is currently running, if true increment the timer.
		intervalId = setInterval(() => {

			props.currentTotalTimer(getTotalTime());

			if(timerProcesses.length > 0) {
				let i = 0;

				timerProcesses.forEach(singleProcess => {
					if(singleProcess.running === 'true') {
						incrementRowTimer(i)
					}
					i++;
				})

				setSecondsTaken(secondsTaken + 1);

				if(secondsTaken % 30 === 0) {
					if(props.isLocked === false) {
						saveTimer();
					}
				}
			}
		}, 500);
		return () => {
			clearInterval(intervalId);
		};
	}, [timerProcesses, incrementRowTimer, loaded, props.travellerId, props.status]);

	const round = (n, dp) => {
		const h = +('1'.padEnd(dp + 1, '0')) // 10 or 100 or 1000 or etc
		return Math.round(n * h) / h
	}

	/**
	 * Checks if the scrap is being edited by another user
	 * 
	 * @returns {Boolean} Returns true or false depending if the scrap is currently locked
	 */
	function isTimerLocked() {
		if(props.isLocked === false || props.currentLoggedInUser.id === props.isLocked.ID) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Send the data for the traveller timer to the WordPress backend
	 */
	function saveTimer() {
		var _timerProcesses = [...timerProcesses];
		var newProcesses = [];

		if(_timerProcesses.length > 0) {
			_timerProcesses.forEach((process) => {
				if(process.operator !== '' && process.process !== '') {
					newProcesses.push(process);
				}
			});
		}

		if(!isTimerLocked()) {
			axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + props.travellerId, {
				"timing" : newProcesses
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then().catch(err => console.log(err));

			if(true) {
				axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + props.travellerId, {
					"status" : "planner-in-progress"
				}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
				.then().catch(err => console.log(err));	
			}
		}
	}

	/**
	 * Add a process to the table
	 */
	function addProcess() {
		const updatedTimerProcesses = [...timerProcesses];

		updatedTimerProcesses.unshift({
			completed_time: 0,
			process: '',
			operator: '',
			running: 'false',
			quantity: null,
			assembled: null,
			saved: false,
			dates: [],
			sign_off_data: []
		});

		props.setStatusInProgress();

		setTimerProcesses(updatedTimerProcesses);
	}

	/**
	 * Create the processes for the dropdown for the process
	 * 
	 * @returns {array} An array of processes to add to the table
	 */
	function getProcesses() {
		let newProcesses = [];

		if(availableProcess.length > 0) {
			availableProcess.forEach(singleProcess => {
				if(singleProcess.description) {
					singleProcess.title = singleProcess.name + ' (' + singleProcess.description + ')'
				} else {
					singleProcess.title = singleProcess.name
				}

				newProcesses.push(singleProcess);
			});
		}

		return newProcesses;
	}

	/**
	 * Sets the name of the row process based on the rows current position
	 * 
	 * @param {Object} e The event of the dropdown
	 * @param {int} index The current index of the row
	 */
	function setRowProcessTime(e, index) {
		let _timer_processes = [...timerProcesses];

		_timer_processes[index]['process'] = e.value;

		setTimerProcesses(_timer_processes);
	}

	/**
	 * Increments the timer for the row being updated
	 * 
	 * @param {int} index The index of the row being updated
	 */
	function incrementRowTimer(index) {
		let _timer_processes = [...timerProcesses];

		_timer_processes[index]['completed_time'] = _timer_processes[index]['completed_time'] + 1;

		setTimerProcesses(_timer_processes);
	}

	/**
	 * Sets the operator for the row being updated
	 * 
	 * @param {object} e The even for the dropdown
	 * @param {int} index The index for the row being updated
	 */
	function setRowProcessOperator(e, index) {
		let _timer_processes = [...timerProcesses];

		_timer_processes[index]['operator'] = e.value;

		setTimerProcesses(_timer_processes);
	}

	function emptyDropdownMessage() {
		return <>
			<span>Missing Product Processes</span>
			{
				props.capabilities.edit_products === true && (
					<div className="mt-2" >
						<Link to={'/product/' + props.traveller.product_id} style={{ fontSize: '0.8rem' }}>Add Processes</Link>
					</div>
				)
			}
		</>
	}

	/**
	 * Create the dropdown for the user to select the process for the row
	 * 
	 * @param {object} rowData The data for the current row
	 * @param {object} props The props for the current row
	 * @returns {html} The dropdown to let the user select the process
	 */
	function getProcess(rowData, props) {
		if(rowData.operator) {
			let isDisabled = false;

			if(rowData.quantity != null || rowData.running === 'true' || isTimerLocked()) {
				isDisabled = true; 
			}

			if(!isDisabled) {
				return <Dropdown value={rowData.process} emptyMessage={emptyDropdownMessage()} className="w-100" onChange={(e) => {
					setRowProcessTime(e, props.rowIndex);
				}} options={getProcesses()} optionLabel="title" placeholder="Select a Process" disabled={isDisabled} />
			} else {
				if(rowData.process.activity) {
					return rowData.process.activity.title;
				} else {
					return rowData.process.title;
				}
			}
		}
	}

	function getQuantity(rowData, props) {
		if(rowData.process.name !== '' && rowData.quantity != null) {
			return (<><div>
				<span className="d-block">{rowData.quantity}</span>
			</div>
			</>);
		} else {
			return <><div>
			<span className="d-block">0</span>
			</div>
		</>
		}
	}

	function getExpectedQuantity(rowData, props) {
		let completedTime = 0;

		rowData.dates.forEach((e) => {
			let diff;

			if(e.end) {
				diff = new Date(e.end) - new Date(e.start);
			} else {
				diff = new Date() - new Date(e.start);
			}

			completedTime += (diff / 1e3);
		})

		let expectedQuantity = Math.floor(completedTime / rowData.process.target_time);

		if(isNaN(expectedQuantity)) {
			expectedQuantity = '0';
		}

		return expectedQuantity;
	}

	/**
	 * Create the input for the user to select the quantity for the row
	 * 
	 * @param {object} rowData The data for the current row
	 * @param {object} props The props for the current row
	 * @returns {html} The input to let the user select the quantity
	 */
	function getQuantityEditor(options) {
		let processCount = 1;
		let rowData = options.rowData;

		if(rowData.process.quantity) {
			processCount = rowData.process.quantity;
		}

		if(rowData.process.name !== '' && rowData.running === "false" && rowData.completed_time > 0) {
			return (<><div className="p-inputgroup">

				<InputNumber disabled={isTimerLocked()} value={rowData.quantity} useGrouping={false} max={props.quantity} className="w-50" placeholder="Quantity" onBlur={(e) => {
					let _timer_processes = [...timerProcesses];

					_timer_processes[options.rowIndex].quantity = e.target.value;

					setTimerProcesses(_timer_processes);

					axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + getTravellerId() + '/timing', {
						"process_id" : _timer_processes[options.rowIndex]['process_id'],
						"quantity" : _timer_processes[options.rowIndex]['quantity'],
					}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
					.then().catch(err => console.log(err))

					saveTimer(); 
			}} />
			</div>
			<span>({timerProcesses[options.rowIndex].quantity / processCount + " Pack" + (timerProcesses[options.rowIndex].quantity / processCount > 1 || timerProcesses[options.rowIndex].quantity / processCount == 0 ? 's' : '')})</span>
			</>);
		}
	}

	/**
	 * Create the dropdown for the user to select the operator for the row
	 * 
	 * @param {object} rowData The data for the current row
	 * @param {object} props The props for the current row
	 * @returns {html} The dropdown to let the user select the operator
	 */
	function getOperator(rowData, rowProps) {
		let isDisabled = false;

		if(rowData.quantity != null || rowData.running === 'true' || isTimerLocked()) {
			isDisabled = true;
		}

		if(!isDisabled) {
			if(!rowData.operator) {
				props.operators.forEach(operator => {
					if(operator.id === props.capabilities.user_id) {
						rowData.operator = operator;
					}
				});
			}
			return <Dropdown value={rowData.operator} className='w-100' onChange={(e) => {setRowProcessOperator(e, rowProps.rowIndex)}} options={props.operators} optionLabel="name" placeholder="Select an Operator" disabled={isDisabled} />
		} else {
			if(props.capabilities.edit_employees) {
				return <Link to={"/employee/" + rowData.operator.id}>{rowData.operator.name}</Link>;
			} else {
				return rowData.operator.name;
			}
		}
	}

	function getTravellerId() {
		return props.travellerId;
	}

	/**
	 * Create the HTML for the timer for the processes
	 * 
	 * @param {object} rowData The data for the current row
	 * @param {object} props The props for the current row
	 * @returns {html} The HTML for the timer
	 */
	function getTimer(rowData, props) {
		let completedTime = 0;

		rowData.dates.forEach((e) => {
			let diff;

			if(e.end) {
				diff = new Date(e.end) - new Date(e.start);
			} else {
				diff = new Date() - new Date(e.start);
			}

			completedTime += (diff / 1e3);
		})

		if(rowData.process !== '' && rowData.operator.id !== '') {
			return <>
			{
				completedTime > 0 && (<>
					<Tooltip autoHide={false} target=".timer" />
					<div className="bg-dark px-2 py-1 text-white d-inline-block rounded timer" data-pr-tooltip={"Single Average: " + getJobTimeFormat(Math.floor(completedTime) / rowData.quantity)}
					data-pr-position="right"
					data-pr-at="right+5 center"
					data-pr-my="left center"><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.75rem' }} ></i>{getJobTimeFormat(Math.floor(completedTime))}</div>
				</>)
			}
			<div className="d-flex justify-content-start align-items-center">
				{
					rowData.running === 'true' && !isTimerLocked() && (<>
						<button className="btn btn-warning text-white m-0 px-2 py-1 timer-button mt-2" onClick={async (e) => {
							let _timer_processes = [...timerProcesses];

							_timer_processes[props.rowIndex]['running'] = 'false';

							_timer_processes[props.rowIndex]['dates'][_timer_processes[props.rowIndex]['dates'].length - 1] = {
								start: _timer_processes[props.rowIndex]['dates'][_timer_processes[props.rowIndex]['dates'].length - 1].start,
								end: Moment().milliseconds(0).toISOString()
							}

							let expectedQuantity;

							completedTime = new Date(_timer_processes[props.rowIndex]['dates'][_timer_processes[props.rowIndex]['dates'].length - 1].end) - new Date(_timer_processes[props.rowIndex]['dates'][_timer_processes[props.rowIndex]['dates'].length - 1].start);

							expectedQuantity = Math.floor((completedTime / 1e3) / rowData.process.target_time)

							if(isNaN(expectedQuantity)) {
								expectedQuantity = '0';
							}

							setExpectedIntervalQuantity(expectedQuantity);

							setTimerProcesses(_timer_processes);
							setShowQuantity(true);
							setDialogRowData({rowData: rowData, props: props});
							saveTimer();

							await addNotePause(_timer_processes[props.rowIndex], props.rowIndex);
						}}><i className="pi pi-pause" style={{ fontSize: '0.75rem' }}></i> Pause</button>
					</>)
				}
				{
					rowData.running === 'false' && !isTimerLocked() && (
						<button className="btn btn-success text-white m-0 px-2 py-1 timer-button mt-2" onClick={async (e) => {
							let _timer_processes = [...timerProcesses];

							_timer_processes[props.rowIndex]['running'] = 'true';
							_timer_processes[props.rowIndex]['dates'].push({
								start: Moment().milliseconds(0).toISOString(),
								end: null
							});

							setTimerProcesses(_timer_processes);
							saveTimer();

							await addNoteAdd(_timer_processes[props.rowIndex], props.rowIndex);
						}}><i className="pi pi-play" style={{ fontSize: '0.75rem' }}></i> Start</button>
					)
				}
			</div>
			</>;
		}
	}

	async function addNoteAdd(data, id) {
		id = timerProcesses.length - id;
		await axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + props.travellerId, {
			"note" : "Started activity #" + id + ": " + data.process.name,
			"note_author" : data.operator.id
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err));

		props.updateNotes();
	}

	async function addNoteQuantity(data, id, qty, total) {
		id = timerProcesses.length - id;
		await axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + props.travellerId, {
			"note" : 'Assembled "' + qty + '" for activitiy #' + id + ": " + data.process.name + " (total assembled: " + total + ")",
			"note_author" : data.operator.id
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err));

		props.updateNotes();
	}

	async function addNotePause(data, id) {
		id = timerProcesses.length - id;
		await axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + props.travellerId, {
			"note" : "Paused activity #" + id + ": " + data.process.name,
			"note_author" : data.operator.id
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err));

		props.updateNotes();
	}

	/**
	 * Checks if there are any outstanding processes which have not been filled in
	 * 
	 * @returns {boolean} True or false depending if the last item on the process table has not been filled in
	 */
	function addRowDisabled() {
		let isDisabled = false;

		if(timerProcesses) {
			timerProcesses.forEach(process => {
				if(process.name === '') {
					isDisabled = true;
				}
			});
		}

		if(isTimerLocked()) {
			isDisabled = true;
		}

		if(props.status === 'planner-completed' || props.status === 'planner-on-hold') {
			isDisabled = true;
		}

		return isDisabled;
	}

	/**
	 * Get the total time of all of the processes on the traveller
	 * 
	 * @returns {int} The total time traveller has used in seconds
	 */
	function getTotalTime() {
		let totalTime = 0;

		if(timerProcesses.length > 0) {
			timerProcesses.forEach(process => {
				let completedTime = 0;
				process.dates.forEach((e) => {
					let diff;
		
					if(e.end) {
						diff = new Date(e.end) - new Date(e.start);
					} else {
						diff = new Date() - new Date(e.start);
					}
		
					completedTime += (diff / 1e3);
				})

				totalTime = totalTime + Math.floor(completedTime);
			});
		}

		return Math.floor(totalTime);
	}

	/**
	 * Return the current index of the note
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @param {Object} props The properties associated to the row
	 * @returns The current row number (+1 as rows start from 0)
	 */
	function getProcessNumber(rowData, props) {
		return timerProcesses.length - props.rowIndex;
	}

	/**
	 * When user is finished editing update the state of the product
	 * 
	 * @param {Object} e The form event
	 */
	async function onRowEditComplete(e) {
		let _timers = [...timerProcesses];
		let { newData, index } = e;

		let dateObject = new Date(newData.date_start);

		// Work out the new completed time
		dateObject.setSeconds(dateObject.getSeconds() + newData.completed_time);

		newData.date_end = Moment(dateObject).toISOString();

		_timers[index] = newData;

		setTimerProcesses(_timers);

		if(!isTimerLocked()) {
			axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + getTravellerId() + '/timing', {
				"process_id" : _timers[index]['process_id'],
				"date_end" : _timers[index]['date_end'],
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then().catch(err => console.log(err))
		}
	}

	function timerEditor(options) {
		let totalSeconds = options.rowData.completed_time;

		let hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');

		if(hours < 10) {
			hours = "0" + hours;
		}

		let minutes = (Math.floor(totalSeconds / 60) % 60).toString().padStart(2, '0');

		if(minutes > 10) {
			minutes = "0" + minutes;
		}

		let seconds = (totalSeconds % 60).toString().padStart(2, '0');

		return <>
			<div className="d-flex align-items-end">
				<InputNumber useGrouping={false} size={1} value={hours} onChange={(e) => {totalSeconds = parseInt(e.value * 60 * 60) + parseInt(minutes * 60) + parseInt(seconds); options.editorCallback(totalSeconds); saveTimer()}} /><span className="mx-2 display-5"> : </span>
				<InputNumber useGrouping={false} size={1} max="59" value={minutes} onChange={(e) => {totalSeconds = parseInt(hours * 60 * 60) + parseInt(e.value * 60) + parseInt(seconds); options.editorCallback(totalSeconds); saveTimer()}} /><span className="mx-2 display-5"> : </span>
				<InputNumber useGrouping={false} size={1} max="59" value={seconds} onChange={(e) => {totalSeconds = parseInt(hours * 60 * 60) + parseInt(minutes * 60) + parseInt(e.value); options.editorCallback(totalSeconds); saveTimer()}} />
			</div>
		</>
	}

	function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
	}

	function ifSignOffEnabled() {
		if(props.isLocked === false) {
			return false;
		} else {
			return true;
		}
	}

	function getSignOff(rowData, props) {
		let html;

		if(rowData.process.sign_off === true && rowData.sign_off_data !== undefined && rowData.quantity > 0) {
			if(rowData.sign_off_data.supervisor) {
				html = <><span>{rowData.sign_off_data.supervisor.name}</span><span className="d-block" style={{fontSize: '0.7rem'}}>{formatDate(rowData.sign_off_data.date, true)}</span></>;
			} else {
				html = <>
				<Button className="btn btn-sm btn-primary" onClick={(e) => {
					setShowSignOff(true);
					setDialogRowData({rowData: rowData, props: props});
					saveTimer();
				}} disabled={ifSignOffEnabled()}>Sign Off</Button>
			</>;
			}
		} else {
			html = 'N/A';
		}

		return html;
	}

	async function refreshTraveller() {
		if(!isTimerLocked()) {
			// Update the system to save the change
			await axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + getTravellerId(), {
				"refresh" : true,
				"note" : "Traveller activities have been refreshed"
			}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}})
			.then()
			.catch(err => console.log(err));

			props.updateNotes();
			props.updateSync();
		}
	}

	let rowindex = 0;

	if(dialogRowData !== null) {
		rowindex = dialogRowData.props.rowIndex;
	}

	let _timerProcesses = [...timerProcesses];

	let operatorName = '';
	let processName = '';
	let DialogQuantity = '';

	if(dialogRowData !== null) {
		operatorName = _timerProcesses[rowindex].operator.name;
		processName = _timerProcesses[rowindex].process.title;
		DialogQuantity = _timerProcesses[rowindex].quantity;
	}

	return (
		<>
			<Dialog blockScroll={true} closable={false} closeOnEscape={false} header={"Sign Off: " + operatorName + " - " + processName + " x " + DialogQuantity} style={{ width: '60vw' }} visible={showSignOff} onHide={() => setShowSignOff(false)}>
				<Dropdown value={selectedSupervisor} className='w-100' options={props.supervisors} optionLabel="name" placeholder="Select a Supervisor" onChange={(e) => {
					setSelectedSupervisor(e.target.value);
				}} />
				<Button className="mt-3" icon="pi pi-pencil me-1" onClick={
					(e) => {
						setShowSignOff(false);

						_timerProcesses[dialogRowData.props.rowIndex]['sign_off_data'] = {
							supervisor: selectedSupervisor,
							date: new Date()
						}

						toast.current.show({ severity: 'success', summary: 'Sign Off', detail: 'Supervisor signed off.' });

						saveTimer();
					}
				}>&nbsp;Sign Off</Button>
			</Dialog>
			<Dialog blockScroll={true} closable={false} closeOnEscape={false} header={operatorName + " - " + processName} style={{ width: '35vw' }} visible={showQuantity} onHide={() => setShowQuantity(false)}>
				<p>Insert order quantity assembled during last interval for above activity</p>
				<p>Expected Quantity: {expectedIntervalQuantity}</p>
				<div className="d-flex">
					<InputNumber min="1" useGrouping={false} className="w-100" placeholder="Quantity" onValueChange={(e) => {
						setDialogQuantity(e.target.value);
						let _timer_processes = [...timerProcesses];

						if(_timer_processes[props.rowIndex]['quantity'] !== undefined) {
							_timer_processes[props.rowIndex]['quantity'] = _timer_processes[props.rowIndex]['quantity'] + e.target.value;
						} else {
							_timer_processes[props.rowIndex]['quantity'] = e.target.value;
						}

						_timer_processes[props.rowIndex]['quantity'] = _timer_processes[props.rowIndex]['quantity'] + e.target.value;

						setTimerProcesses(_timer_processes);
					}} showButtons buttonLayout="horizontal" step={1} decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
					<div>
						<Button className="ms-3" icon="pi pi-plus me-1" onClick={
							async (e) => {
								let _timerProcesses = [...timerProcesses];

								if(dialogQuantity !== null) {
									setShowQuantity(false);
	
									_timerProcesses[dialogRowData.props.rowIndex]['dates'][_timerProcesses[dialogRowData.props.rowIndex]['dates'].length - 1]['quantity'] = dialogQuantity;
	
									if(_timerProcesses[dialogRowData.props.rowIndex]['quantity']) {
										_timerProcesses[dialogRowData.props.rowIndex]['quantity'] = parseInt(dialogQuantity) + parseInt(_timerProcesses[dialogRowData.props.rowIndex]['quantity']);
									} else {
										_timerProcesses[dialogRowData.props.rowIndex]['quantity'] = dialogQuantity;
									}
	
									toast.current.show({ severity: 'success', summary: 'Quantity', detail: 'Quantity added.' });
	
									await addNoteQuantity(_timerProcesses[dialogRowData.props.rowIndex], dialogRowData.props.rowIndex, dialogQuantity, _timerProcesses[dialogRowData.props.rowIndex]['quantity']);
	
									setDialogQuantity(null);

									saveTimer();
								} else {
									toast.current.show({ severity: 'error', summary: 'Quantity', detail: 'Quantity input required.' });
								}
							}
						}>&nbsp;Assembled</Button>
					</div>
				</div>
			</Dialog>
			<ConfirmDialog blockScroll={true} visible={confirmRefreshDialog} onHide={() => setConfirmRefreshDialog(false)} message="Are you sure you want to sync processes?" header="Confirmation" icon="pi pi-exclamation-triangle" accept={refreshTraveller} />
			<Toast ref={toast} position="bottom-right" />
			<div className="container px-0">
				<div className="row">
					{
						process && (
							<div className="col-6 mb-3">
								<InputNumber useGrouping={false} value={quantity} min="1" className="w-100" placeholder="Quantity" onChange={(e) => setQuantity(e.value)} />
							</div>
						)
					}
				</div>
			</div>
			<div className="d-flex justify-content-end">
				{
					props.capabilities.edit_travellers && (
						<>
							<button className="p-button p-component mb-3 ms-2" onClick={addProcess} disabled={addRowDisabled()}><i className="pi pi-plus me-1"></i> Add Activity</button>
							<button className="p-button p-component mb-3 ms-2" onClick={() => setConfirmRefreshDialog(true)} disabled={addRowDisabled()}><i className="pi pi-history me-1"></i> Sync</button>
						</>
					)
				}
			</div>
			<DataTable value={timerProcesses} tableStyle={{ minWidth: '50rem' }} onRowEditComplete={onRowEditComplete} editMode="row">
				<Column field="id" header="Activity #" body={getProcessNumber}></Column>
				<Column field="operator" header="Operator" body={getOperator}></Column>
				<Column field="name" header="Process(es)" headerStyle={{ maxWidth: '17.5rem' }} body={getProcess} bodyStyle={{ maxWidth: '17.5rem' }}></Column>
				<Column field="completed_time" header="Timer" headerStyle={{ minWidth: '9rem', textAlign: 'right' }} body={getTimer} editor={timerEditor}></Column>
				<Column field="quantity" header="Assembled" headerStyle={{ maxWidth: '12.5rem' }} body={getQuantity} editor={getQuantityEditor}></Column>
				<Column field="x_quantity" header="Expected" headerStyle={{ maxWidth: '12.5rem' }} body={getExpectedQuantity}></Column>
				<Column field="signed_off" header="Sign off" headerStyle={{ minWidth: '10rem', textAlign: 'right' }} body={getSignOff}></Column>
				{
					props.capabilities.manage_travellers == true && false && (
						<Column rowEditor headerStyle={{ minWidth: '6rem' }} bodyStyle={{ textAlign: 'right' }}></Column>
					)
				}
			</DataTable>
		</>
	);
}
import React from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import { decode } from 'html-entities';

import { getUrl, formatDate } from './../planner/planner';

class EditScrapForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			scrap: [],
			isLoaded: false,
			reason: '',
			quantity: 1,
			materials: [],
			travellers: [],
			locked: false,
			operators: [],
			maxQuantity: 1,
			operator: null,
			traveller: null,
			material: null,
			toast: React.createRef()
		}
	}

	/**
	 * Runs when the component mounts
	 */
	async componentDidMount() {
		const [getScrapDetails] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/travellers/scraps/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		const [gettravellers] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/travellers/', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		const [getOperators] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/users/production_operator', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		this.setState({
			scrap: getScrapDetails.data,
			locked: getScrapDetails.data.locked,
			reason: getScrapDetails.data.issue,
			quantity: getScrapDetails.data.quantity,
			travellers: gettravellers.data,
			operators: getOperators.data,
			operator: getScrapDetails.data.operator,
			material: getScrapDetails.data.material_id,
			materialTitle: getScrapDetails.data.material_description,
			traveller: getScrapDetails.data.traveller_id,
			isLoaded: true,
		});

		document.title = 'Scrap #' + getScrapDetails.data.number + ' - Planner';

		setInterval(() => {
			this.lockScrap(getScrapDetails);
		}, 15000);

		this.lockScrap(getScrapDetails);
	}

	/**
	 * Lock the scrap to the ID that is editing
	 * 
	 * @param {Object} scrapDetails The data for the current scrap
	 */
	lockScrap(scrapDetails) {
		if(scrapDetails.data.locked === false) {
			axios.put(getUrl() + '/wp-json/planner/v1/travellers/scraps/' + this.props.id, {
				"locked" : Math.floor(Date.now() / 1000) + ':' + this.props.currentLoggedInUser.id
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(res => this.setState({
				isLoaded: true
			})).catch(err => console.log(err))
		}
	}

	/**
	 * Checks if the scrap is being edited by another user
	 * 
	 * @returns {Boolean} Returns true or false depending if the scrap is currently locked
	 */
	isScrapLocked() {
		if((this.state.locked === false || this.props.currentLoggedInUser.id === this.state.locked.ID)) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Handle changing the traveller dropdown by resetting the material and recalculating which materials should be possible to select
	 * 
	 * @param {object} event Information for the select input
	 */
	async changeTraveller(event) {
		const [getTravellers] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/travellers/' + event.value, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		const [getMaterials] = await Promise.all([
			axios.get(getUrl() + '/wp-json/wc/v3/orders/' + getTravellers.data.order.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		this.setState({
			materials: getMaterials.data.bom,
			traveller: event.value,
			material: null
		});

		const { toast } = this.state;

		axios.put(getUrl() + '/wp-json/planner/v1/travellers/scraps/' + this.props.id, {
			"traveller" : event.value,
			"material": null
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then()
		.catch(err => console.log(err));

		toast.current.show({ severity: 'success', summary: 'Scrap', detail: 'Traveller has been updated.' });
	}

	/**
	 * Handle changing the material dropdown
	 * 
	 * @param {object} event Information for the select input
	 */
	changeMaterial(event) {
		let maxQuantity = 0;

		this.state.materials.forEach(data => {
			if(data.id === event.value) {
				maxQuantity = data.quantity
			}
		});

		if(this.state.quantity > maxQuantity) {
			this.setState({
				quantity: maxQuantity
			});
		}

		this.setState({
			maxQuantity: maxQuantity,
			material: event.value
		});

		const { toast } = this.state;

		axios.put(getUrl() + '/wp-json/planner/v1/travellers/scraps/' + this.props.id, {
			"material" : event.value,
			"quantity" : event.maxQuantity
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then().catch(err => console.log(err));

		toast.current.show({ severity: 'success', summary: 'Scrap', detail: 'Traveller has been updated.' });
	}

	/**
	 * Handle changing the operator dropdown
	 * 
	 * @param {object} event Information for the select input
	 */
	changeOperator(event) {
		this.setState({
			operator: event.value,
		});

		const { toast } = this.state;

		axios.put(getUrl() + '/wp-json/planner/v1/travellers/scraps/' + this.props.id, {
			"operator" : event.value
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then().catch(err => console.log(err));

		toast.current.show({ severity: 'success', summary: 'Scrap', detail: 'Operator has been updated.' });
	}

	/**
	 * Handle changing the operator dropdown
	 * 
	 * @param {object} event Information for the select input
	 */
	changeQuantity(event) {
		this.setState({
			quantity: event.value,
		});

		const { toast } = this.state;

		axios.put(getUrl() + '/wp-json/planner/v1/travellers/scraps/' + this.props.id, {
			"quantity" : event.value
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then().catch(err => console.log(err));

		toast.current.show({ severity: 'success', summary: 'Scrap', detail: 'Quantity has been updated.' });
	}

	optionsTemplate(e) {
		if (e) {
			return (
				<div className="flex align-items-center">
					<div>{decode(e.title)}</div>
				</div>
			);
		}

		return '';
	}

	optionTemplate(e) {
		return (
			<div className="flex align-items-center">
				<div>{decode(e.title)}</div>
			</div>
		);
	}

	render() {
		const { isLoaded, scrap, toast, reason, quantity, operators, operator, materialTitle, travellers, traveller } = this.state;

		return (
			<>
				<Toast ref={toast} position="bottom-right" />
				<div className="edit-order-bar position-sticky top-0 p-3">
					<div className="container px-0 d-flex justify-content-between align-items-center">
						<div className="d-flex justify-content-start align-items-center">
							<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.onSidebarOpen(true);}}>
								<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
							</Button>
							<div>
								{
									isLoaded && (<>
										<h1 className="mb-0 h3">Editing Scrap: #{scrap.scrap_number}</h1>
										<strong>Date Created:</strong>&nbsp;{formatDate(scrap.date_reported, true)}
									</>)
								}
							</div>
							{
								!isLoaded && (
									<div>
										<i className="pi pi-spin pi-spinner" style={{ fontSize: '2.8rem' }}></i>
									</div>
								)
							}
						</div>
						<div>
							{
								this.isScrapLocked() && (
									<p className="d-inline mb-0 ms-3"><span className="badge bg-danger d-inline-flex align-items-center display-6"><i className="pi pi-lock me-2"></i> {this.state.locked.data.user_login} is currently editing this scrap</span></p>
								)
							}
						</div>
					</div>
				</div>
				{
					isLoaded && ( // https://stackoverflow.com/questions/70682832/
					<>
						<form className="container px-4 px-xxl-0 mt-5">
							<div className="row">
								<div className="col-12">
									<Fieldset legend="Scrap Information">
										<div className="container">
											<div className="row">
												<div className="col-12 col-md-6 col-xxl-3">
													<strong className="w-100 mb-1 d-block">Material:</strong>
													<span>{materialTitle}</span>
												</div>
												<div className="col-12 col-md-6 col-xxl-3">
													<strong className="w-100 mb-1 d-block">Traveller:</strong>
													{
														this.props.capabilities.edit_travellers && (
															<Dropdown valueTemplate={this.optionsTemplate} itemTemplate={this.optionTemplate} filterInputAutoFocus={false} disabled={this.isScrapLocked()} placeholder="Select a Traveller" value={parseInt(traveller)} options={travellers} optionLabel="title" optionValue="id" filter className="w-full md:w-14rem" onChange={this.changeTraveller.bind(this)} />
														)
													}
													{
														this.props.capabilities.view_travellers && !this.props.capabilities.edit_travellers && (
															<Dropdown valueTemplate={this.optionsTemplate} itemTemplate={this.optionTemplate} filterInputAutoFocus={false} disabled={true} placeholder="Select a Traveller" value={parseInt(traveller)} options={travellers} optionLabel="title" optionValue="id" filter className="w-full md:w-14rem" onChange={this.changeTraveller.bind(this)} />
														)
													}
												</div>
												<div className="col-12 col-md-6 col-xxl-3 mt-2 mt-xxl-0">
													<strong className="w-100 mb-1 d-block">Operator:</strong>
													{
														this.props.capabilities.edit_travellers && (
															<Dropdown filterInputAutoFocus={false} disabled={this.isScrapLocked()} placeholder="Select an Opperator" value={operator} options={operators} optionLabel="name" optionValue="id" filter className="w-full md:w-14rem" onChange={this.changeOperator.bind(this)} />
														)
													}
													{
														this.props.capabilities.view_travellers && !this.props.capabilities.edit_travellers && (
															<Dropdown filterInputAutoFocus={false} disabled={true} placeholder="Select an Opperator" value={operator} options={operators} optionLabel="name" optionValue="id" filter className="w-full md:w-14rem" onChange={this.changeOperator.bind(this)} />
														)
													}
												</div>
												<div className="col-12 col-md-6 col-xxl-3 mt-2 mt-xxl-0">
													<strong className="w-100 mb-1 d-block">Quantity:</strong>
													{
														this.props.capabilities.edit_travellers && (
															<Dropdown filterInputAutoFocus={false} disabled={this.isScrapLocked()} placeholder="Select Quantity" value={quantity} options={Array.from({length:999},(v,k)=>(k+1)).map(String)} filter className="w-full md:w-14rem" onChange={this.changeQuantity.bind(this)} />
														)
													}
													{
														this.props.capabilities.view_travellers && !this.props.capabilities.edit_travellers && (
															<Dropdown filterInputAutoFocus={false} disabled={true} placeholder="Select Quantity" value={quantity} options={Array.from({length:999},(v,k)=>(k+1)).map(String)} filter className="w-full md:w-14rem" onChange={this.changeQuantity.bind(this)} />
														)
													}
												</div>
											</div>
											<div className="col-12 mt-3">
												<strong className="w-100 mb-1 d-block">Reason:</strong>
												{reason}
											</div>
										</div>
									</Fieldset>
								</div>
							</div>
						</form> 
					</>
				)
			}
			</>
    );
  }
}
export default EditScrapForm;